<template>
  <div class="custom-slider relative">
    <!-- <button type="button" class="custom-slider-button prev" :class="{ show: index !== 0 && cards.length > 1}" @click="prev">
      <ion-icon name="chevron-back-outline" />
    </button>
    <button type="button" class="custom-slider-button next" :class="{ show: index !== cards.length - 1 && cards.length > 1}" @click="next">
      <ion-icon name="chevron-forward-outline" />
    </button> -->
    <div class="custom-slider-wrapper shadow-card overflow-hidden rounded w-full" ref="wrapper" style="border: 1px solid #EFF2FD;">
      <template v-for="(card, n) in cards">
        <div
          class="custom-slider-item w-full"
          :class="{ 'active': index === n, 'transform-right': n === index - 1 }"
          ref="item"
          :key="n"
          @click.prevent="click(n)"
        >
          <credit-card-3
            :name="user.name"
            :credit-balance="creditBalance"
            :available-balance="availableBalance"
            :credit-limit="creditLimit"
            :amount-spent="creditBalance"
            :card-status="cardStatus"
            :billing-date="nextBillingDate"
            :card-type="card.card_type"
            @buttonClick="buttonClick"
            @topUpClick="topUpClick"
            @transferClick="transferClick"
            style="width: 600px; max-width: 100%; solid #EFF2FD; border-radius: 8.37383px;"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';

  export default {
    props: {
      cards: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: false
      },
      status: {
        type: String,
        default: null
      },
      account: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        index: null,
        // debitBalance:  null,
        // creditBalance: null,
        // creditLimit: null
      }
    },
    computed: {
      activeCard() {
        return this.cards[this.index];
      },
      availableBalance() {
        if(this.account === "personal") {
          return this.activeCard?.available_balance;
        }
        return this.card.company_account.balance;
      },
      creditBalance() {
        if(this.account === "personal") {
          // return (this.activeCard?.available_balance || 0) - (this.activeCard?.available_credit || 0);
          return (this.activeCard?.available_balance || 0);
        }
        return (this.activeCard?.company_account?.limit || 0) - (this.activeCard?.company_account?.available_credit || 0)
      },
      creditLimit() {
        if(this.account === "personal") {
          return this.activeCard?.credit_card_limit;
        }
        return this.card.company_account?.limit;
      },
      cardType() {
        // :card-type="card.company_account && card.company_account.type"
        if(this.account === "personal") {
          return "personal";
        }
        return this.card.company_account && this.card.company_account.type
      },
      nextBillingDate() {
        const billingDay = this.user.profile?.billing_date;
        if (!billingDay) {
          return null;
        }        
        const date = new Date();
        if(date.getDate() > billingDay) {
          date.setMonth(date.getMonth() + 1);          
        }
        date.setDate(billingDay);
        return date.format('MM dd, Y');
      },
      pinRequested() {
        return this.activeCard?.pin_status;
      },
      cardReady() {
        return this.user.card_request?.status == 'ready';
      },
      cardStatus() {
        if (this.activeCard?.card_type === 'debit' && this.activeCard?.status === 'active') {
          return 'activated';
        }

        if (this.activeCard?.card_type === 'debit' && this.activeCard?.cardStatus === 'not_requested') {
          return 'not_requested';
        }        

        if (this.activeCard?.status === "active" && this.creditBalance == 0) {
          return 'activated'
        }
        if (this.loading && this.creditBalance == 0) {
          return 'activating'
        }
        if (this.pinRequested && this.creditBalance == 0) {
          return 'pin_requested'
        }
        if (this.loading && this.creditBalance == 0) {
          return 'pin_requesting'
        }
        if (this.cardReady && this.creditBalance == 0) {
          return 'ready'
        }
        if (this.creditBalance > 0){
          return 'clear_balance'
        }
        // // if (this.cardRequested) {
        // //   return 'requesting';
        // // }
        // if (this.cardRequested && this.creditBalance == 0) {
        //  return  'requested';
        // }

        return 'not_requested';
      }
    },
    watch: {
      index(index) {
        this.$emit('change', { card: this.activeCard, index });

        const element = this.$refs.item[index];

        const wrapper = this.$refs.wrapper;

        var left = element.offsetLeft;

        $(wrapper).animate({
          scrollLeft: left
        }, 300);
      }
    },
    beforeMount() {
      this.index = 0;
    },
    mounted() {
      this.index = 0;
    },
    methods: {
      buttonClick() {
        this.$emit('buttonClick', { index: this.index, card: this.activeCard });
      },
      transferClick() {
        this.$emit('transferClick', { index: this.index, card: this.activeCard });
      },
      topUpClick() {
        this.$emit('topUpClick', { index: this.index, card: this.activeCard });
      },
      // cardClick() {
      //   this.$emit('buttonClick', { index: this.index, card: this.activeCard });
      // },
      click(index) {
        this.index = index;
      },
      next() {
        this.index = this.index >= this.cards.length - 1 ? 0 : this.index + 1;
      },
      prev() {
        this.index = this.index <= 0 ? this.cards.length - 1 : this.index - 1;
      }
    }
  }
</script>